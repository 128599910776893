import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import Cta from "../components/cta";
import * as Styles from "../styles/effect.module.css";

const QHSE = () => {
  return (
    <Layout pageTitle="Home Page">
      {/* MetaTag */}
      <Helmet
        htmlAttributes={{
          lang: "en",
        }}
      >
        <title>
          Quality, Health, Safety and Environment Policy - KATSANA® Integrated
          Fleet Management & Industrial Operation Solutions
        </title>
        <meta
          name="description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          name="robots"
          content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://www.katsana.com/quality-health-safety-and-environment-policy/"
        />

        {/* Open Graph */}
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.katsana.com/quality-health-safety-and-environment-policy/"
        />
        <meta
          property="og:title"
          content="Quality, Health, Safety and Environment Policy - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
        />
        <meta
          property="og:description"
          content="We are transforming fleet & enterprise operations for the digital age."
        />
        <meta
          property="og:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
        <meta
          property="og:image:alt"
          content="KATSANA Fleet Management & Industrial IoT Sensors Expert"
        />
        <meta property="og:image:width" content="1280" />
        <meta property="og:image:height" content="640" />

        {/* Twitter Card */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@katsanagps" />
        <meta name="twitter:creator" content="@katsanagps" />

        {/* image must be 2:1 max 5mb */}
        <meta
          name="twitter:image"
          content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
        />
      </Helmet>
      {/* Hero Area */}
      <div className="relative p-8 pt-12 pb-24 overflow-hidden xl:px-0 md:pt-32 md:pb-48">
        <StaticImage
          alt="katsana"
          src="../../static/images/photos/aboutus-hero.jpg"
          objectFit="cover"
          className="!absolute inset-0"
        />

        <div className="relative z-10 max-w-6xl mx-auto ">
          <h3 className="pt-24 md:pt-12 text-lg md:text-2xl text-bluegray">
            Important stuff that you need to know
          </h3>
          <h1 className="pt-2 text-3xl font-bold tracking-wide text-white md:leading-tight md:mt-0 md:text-6xl">
            Quality, Health, Safety and Environment Policy
          </h1>
        </div>
      </div>

      <div className=" bg-gradient-to-b from-[hsla(204,26%,93%,1)] to-white relative pb-36">
        <div className="absolute top-0 left-0 w-full">
          <div
            className={
              Styles.tilt +
              " after:bg-[hsla(204,26%,93%,1)] z-10 m-[-1px] after:h-[4vh] md:after:h-[10vh] z-10 "
            }
          ></div>
        </div>
        <div className="max-w-4xl p-8 xl:p-16 mx-auto bg-gradient-to-b from-[hsla(204,26%,93%,0)] to-[hsla(204,26%,99%,0)] via-white text-gray-800  relative">
          <h3 className="text-2xl font-bold md:text-center">
            KATSANA HOLDINGS
          </h3>
          <p className="mt-2 md:text-center">and its subsidiaries</p>
          <p className="mt-12 leading-7">
            KATSANA policies are based on three important pillars which all are
            critical in determining the success of the company, our employees
            and our customers.
          </p>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              Our customers are the reason of our existence. The level of
              satisfaction of our customers determines our past, present and
              future success;
            </li>
            <li>
              Our employees are our family, everyone plays a crucial role in
              determining the success of the company. They represent the
              knowledge and skills of our organization. The level of
              satisfaction of every employee is key for the development of this
              knowledge, skills, capability and experience that bolsters our
              success;
            </li>
            <li>
              Our profitability is, as with all commercial organizations, an
              important condition of our existence. The degree of profitability
              determines the satisfaction of our shareholder and thus for our
              continuity. It gives us the opportunity to invest in people,
              resources and products.
            </li>
          </ul>
          <p className="mt-12 leading-7">
            To stay successful within our company, we constantly aim to improve
            our business processes and products to meet, or exceed market needs
            and quality standards. To achieve this, the management of Pixelated
            stated that
          </p>
          <ul className="mt-4 ml-6 leading-7 list-disc">
            <li>
              Business processes and responsibilities are clear and unambiguous
              at all times. We aim to further strengthen our professionalism
              through certifications to quatify our quality and capability.
            </li>
            <li>
              Protection and improvement of physical and mental health, safety
              and security of our family at all times.
            </li>
            <li>
              Aim to fulfill customer requirements and ensure continuous
              customer satisfaction.
            </li>
            <li>
              Disruptions are bound to happen, yet we will plan, respond and
              recover from any emergency and crisis in a transparent, orderly
              and with best-interest of our customers and organization.
            </li>
            <li>
              Impact on environment to be minimized through pollution
              prevention, reduction of natural resource consumption and
              emissions, and the reduction and recycling of waste.
            </li>
            <li>
              We will apply our technical skiils to all QHSE aspects in the
              design and engineering of our services and products;
            </li>
            <li>
              Communication is open and transparent with stakeholders to ensure
              an understanding of our QHSE policies, standards, programs and
              performance;
            </li>
            <li>
              Improvement of our performance on issues relevant to our
              stakeholders that are of global concern and on which we can have a
              n impact, and share with them our knowledge of successful QHSE
              programs and initiatives.
            </li>
          </ul>

          <p className="mt-6 leading-7">
            This Policy shall be regularly reviewed to ensure ongoing
            suitability. The commitments listed are in addition to our basic
            obligation to comply with Pixelated standards, as well as all
            applicable laws and regulations where we operate. This is critical
            to our business success because it allows us to systematically
            minimize all losses and adds value for all our stakeholders.
          </p>
          <p className="mt-12">Syed Ahmad Fuqaha,</p>
          <p>Managing Director.</p>
        </div>
      </div>
      <Cta />
    </Layout>
  );
};

export default QHSE;
